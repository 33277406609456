import Swiper, { EffectFade, Pagination, Autoplay } from 'swiper'
// import Swiper styles v6
import 'swiper/swiper-bundle.css'

Swiper.use([EffectFade, Pagination, Autoplay])

const option = {
  // オプションパラメータ(一部のみ抜粋)
  loop: true, // 最後のスライドまで到達した場合、最初に戻らずに続けてスライド可能にするか。
  speed: 800, // スライドが切り替わるトランジション時間(ミリ秒)。
  spaceBetween: 0, // スライド間の余白サイズ(ピクセル)
  effect: "fade",
  fadeEffect: { 
    crossFade: true
  },
  autoplay: {
    delay: 7000, // スライドが切り替わるまでの表示時間(ミリ秒)
  },
  // 前後スライドへのナビゲーションを表示する場合
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
}

const swiper = new Swiper('.swiper-container', option)
